
    import { defineComponent } from "vue";
    import vm from '@/main';
    import { categoryModel} from "@/models/WorkspaceModel";
    import SecondNavbar from "@/components/SecondNavbar.vue";
    import CategoryApi from "@/apis/CategoryApi";

    export default defineComponent({
        name: "CategoryWorkspace",
        components: {
            SecondNavbar,
        },
        data() {
            return {
                categoryFolder: new Array<categoryModel>(),
                searchKeyword: "",
                type: "",
                pages:{
                    pageNumber: 1, // 目前頁碼，從1開始
                    pageSize: 30, // 頁面大小，任意>0
                    totalPages: 3, // 總頁面數，從0開始
                },
                showModal: false,
                newCatName:"",
                stayIsQuiz: false,
                currentName:"",
                currentId:"",
            };
        },
        mounted() {

        },
        watch: {
            async stayIsQuiz() {
                console.log("stayIsQuiz = ", this.stayIsQuiz)
                this.$store.commit('updateLoading', true);

                try{
                    if(this.stayIsQuiz == true) this.type = "quiz"
                    else    this.type = "course"
                    const categoryQueryResult = JSON.parse(JSON.stringify(await CategoryApi.getCategoryByName(this.searchKeyword ,1, this.type)));
                    this.categoryFolder = categoryQueryResult.results;
                    this.pages.totalPages = categoryQueryResult.totalPages;
                    this.pages.pageNumber = categoryQueryResult.pageNumber;

                }
                catch(e){
                    console.log(e);
                }
                finally{
                    this.$store.commit('updateLoading', false);
                }
            },
        },
        methods: {
            clickIsExamCategory(isExamQuiz: boolean){
                this.stayIsQuiz = isExamQuiz;
            },
            handleModalClose(){
                console.log("Debug: handleModalClose");
                this.showModal = false
            },
            async editCategoryName(){
                if(this.newCatName == ""){
                    alert("尚未輸入分類名稱");
                    return
                }
                else{
                    console.log("edit");
                    const nameFormData = new FormData();
                    try{
                        nameFormData.append('name', this.newCatName);
                        const updateNameResult = JSON.parse(JSON.stringify(await CategoryApi.updateCategoryName(this.currentId, nameFormData)));
                    }
                    catch(e){
                        console.log(e);
                    }
                    finally{
                        this.$store.commit('updateLoading', false);
                        this.showModal = false
                        alert("更改成功")
                        window.location.reload()
                    }

                }
            },
            async onPageChange(newPage:number){
                console.log("Debug: onPageChange newPage=.", newPage);
                console.log("Debug: onPageChange buttonSearch keyword= ", this.searchKeyword);
                if(newPage < 1)  newPage = 1;
                else if(newPage > this.pages.totalPages)    newPage = this.pages.totalPages
                this.$store.commit('updateLoading', true);
                if(this.$router.currentRoute.path.includes('exam')) this.type = "quiz"
                else    this.type = "course"
                try{
                    const courseQueryResult = JSON.parse(JSON.stringify(await CategoryApi.getCategoryByName(this.searchKeyword ,newPage, this.type)));
                    this.categoryFolder = courseQueryResult.results;
                    this.pages.totalPages = courseQueryResult.totalPages;
                }
                catch(e){
                    console.log(e);
                }
                finally{
                    this.$store.commit('updateLoading', false);
                }
            },
            clearKeyword(){
                console.log("Debug: clearKeyword");
                this.searchByname("");
            },
            async searchByname(keyword:string){
                this.searchKeyword = keyword;
                console.log("Debug: buttonSearch keyword= ", this.searchKeyword);
                this.$store.commit('updateLoading', true);
                if(this.$router.currentRoute.path.includes('exam')) this.type = "quiz"
                else    this.type = "course"
                try{
                    const categoryQueryResult = JSON.parse(JSON.stringify(await CategoryApi.getCategoryByName(this.searchKeyword ,1, this.type)));
                    this.categoryFolder = categoryQueryResult.results;
                    this.pages.totalPages = categoryQueryResult.totalPages;
                    this.pages.pageNumber = categoryQueryResult.pageNumber;

                }
                catch(e){
                    console.log(e);
                }
                finally{
                    this.$store.commit('updateLoading', false);
                }
            },
            editCategory(name:string, id:string){
                this.currentName = name;
                this.currentId = id;
                this.showModal = true
            },
            async deleteCategory(index:number){
                const categoryName = this.categoryFolder[index].name;
                this.$bvModal.msgBoxConfirm(`此分類底下的所有課程/測驗/銷售方案均會被一併刪除，确定要刪除「${categoryName}」分類吗?`,{
                    title: 'Please Confirm',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: '确定',
                    cancelTitle: '取消',
                    footerClass: 'p-2',
                    hideHeaderClose: true,
                    centered: true
                })
                .then(async value => {
                if(value) {
                    const userConfirmation = prompt("确认要刪除吗？（输入“确认刪除”进行刪除）");
                    console.log("userConfirmation: " + userConfirmation);
                    if(userConfirmation == null){
                        return false;
                    }
                    else if (userConfirmation !== "确认刪除") {
                        alert("輸入錯誤")
                        this.deleteCategory(index);
                        return false;
                    }
                    else{
                        try{
                            await CategoryApi.deleteCategory(this.categoryFolder[index].uuid);
                            this.categoryFolder.splice(index, 1);
                            alert("刪除成功")
                        }
                        catch(e){
                            console.log(e);
                        }
                        finally{

                            this.$store.commit('updateLoading', false);
                        }
                    }
                }
                })
                .catch(err => {
                // An error occurred
                    console.error('delete category modal closed due to an error', err);
                })

            },
        },
        async created(){
            this.$store.commit('updateLoading', true);
            try{
                if(this.$router.currentRoute.path.includes('exam')){
                    this.stayIsQuiz = true;
                    this.type = "quiz"
                }
                else{
                    this.type = "course"
                    this.stayIsQuiz =false
                }
                const categoryQueryResult = JSON.parse(JSON.stringify(await CategoryApi.getCategoryByName("" ,this.pages.pageNumber, this.type)));
                this.categoryFolder = categoryQueryResult.results;
                this.pages.totalPages = categoryQueryResult.totalPages;
                this.pages.pageNumber = categoryQueryResult.pageNumber;
            }
            catch(e){
                console.log(e);
            }
            finally{
                this.$store.commit('updateLoading', false);
            }
        }
    });
